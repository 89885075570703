// extracted by mini-css-extract-plugin
export var hero = "isa-module--hero--9dzsZ";
export var heroInfo = "isa-module--hero-info--4QNAc";
export var heroRight = "isa-module--hero-right--pHXBO";
export var info = "isa-module--info--Zm24K";
export var infoButtonWrapper = "isa-module--info-button-wrapper--VuLzg";
export var infos = "isa-module--infos--AqHUE";
export var isa = "isa-module--isa--u0drc";
export var isaInfo = "isa-module--isa-info--CGkxD";
export var packageForm = "isa-module--package-form--Mx1-k";
export var packageImageWrapper = "isa-module--package-image-wrapper--a3CPv";
export var process = "isa-module--process--dLDPI";
export var processDownload = "isa-module--process-download--8T6iB";
export var processExample = "isa-module--process-example--x-D4Z";
export var processInfo = "isa-module--process-info--stiI7";
export var seoHeading = "isa-module--seo-heading--wU81C";
export var smallNote = "isa-module--small-note--tcQLY";